var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBLBASEINFO" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.mappingType,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "방지시설명",
                            name: "envWaterMstPreventiveName",
                          },
                          model: {
                            value: _vm.data.envWaterMstPreventiveName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.data,
                                "envWaterMstPreventiveName",
                                $$v
                              )
                            },
                            expression: "data.envWaterMstPreventiveName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "방지시설 관리번호",
                            name: "envWaterMstPreventiveNo",
                          },
                          model: {
                            value: _vm.data.envWaterMstPreventiveNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "envWaterMstPreventiveNo", $$v)
                            },
                            expression: "data.envWaterMstPreventiveNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-dept", {
                          attrs: {
                            type: "edit",
                            editable: _vm.editable,
                            label: "관리부서",
                            name: "deptCd",
                          },
                          model: {
                            value: _vm.data.deptCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "deptCd", $$v)
                            },
                            expression: "data.deptCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "LBLUSEFLAG",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.data.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "useFlag", $$v)
                            },
                            expression: "data.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "grid",
            attrs: {
              title: "기본 가동(조업)시간",
              tableId: "grid",
              columns: _vm.grid.columns,
              data: _vm.grid.data,
              usePaging: false,
              hideBottom: true,
              columnSetting: false,
              isFullScreen: false,
              filtering: false,
              isExcelDown: false,
              gridHeight: "120px",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "방지시설 고장이력" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && _vm.popupParam.envWaterMstPreventiveId
                        ? _c("c-btn", {
                            attrs: { label: "LBLREG", icon: "add" },
                            on: { btnClicked: _vm.addData2 },
                          })
                        : _vm._e(),
                      _vm.editable && _vm.saveable
                        ? _c("c-btn", {
                            attrs: { label: "LBLREMOVE", icon: "remove" },
                            on: { btnClicked: _vm.delData2 },
                          })
                        : _vm._e(),
                      _vm.editable && _vm.saveable
                        ? _c("c-btn", {
                            attrs: {
                              url: _vm.saveUrl2,
                              isSubmit: _vm.isSave2,
                              param: _vm.data2,
                              mappingType: _vm.saveType2,
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveData2,
                              btnCallback: _vm.saveCallback2,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7",
                  },
                  [
                    _c("c-table", {
                      ref: "grid2",
                      attrs: {
                        title: "고장이력",
                        tableId: "grid2",
                        columnSetting: false,
                        isFullScreen: false,
                        columns: _vm.grid2.columns,
                        data: _vm.grid2.data,
                        gridHeight: "300px",
                      },
                      on: { rowClick: _vm.rowClick },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5",
                  },
                  [
                    _c("q-form", { ref: "editForm2" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                required: true,
                                range: true,
                                editable: _vm.editable && _vm.dataeditable,
                                label: "고장기간",
                                name: "breakdownDts",
                              },
                              model: {
                                value: _vm.data2.breakdownDts,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data2, "breakdownDts", $$v)
                                },
                                expression: "data2.breakdownDts",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-text", {
                              attrs: {
                                editable: _vm.editable && _vm.dataeditable,
                                label: "고장상태",
                                name: "breakdownStatus",
                              },
                              model: {
                                value: _vm.data2.breakdownStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data2, "breakdownStatus", $$v)
                                },
                                expression: "data2.breakdownStatus",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-text", {
                              attrs: {
                                editable: _vm.editable && _vm.dataeditable,
                                label: "조치사항",
                                name: "breakdownAction",
                              },
                              model: {
                                value: _vm.data2.breakdownAction,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data2, "breakdownAction", $$v)
                                },
                                expression: "data2.breakdownAction",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-text", {
                              attrs: {
                                editable: _vm.editable && _vm.dataeditable,
                                label: "특기사항",
                                name: "remark",
                              },
                              model: {
                                value: _vm.data2.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data2, "remark", $$v)
                                },
                                expression: "data2.remark",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                editable: _vm.editable && _vm.dataeditable,
                                label: "조치일",
                                name: "bactionDt",
                              },
                              model: {
                                value: _vm.data2.bactionDt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data2, "bactionDt", $$v)
                                },
                                expression: "data2.bactionDt",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }